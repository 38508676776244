import React from 'react'

const Instagram = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      aria-labelledby="instagram"
      role="presentation"
    >
      <title id="instagram">Instagram</title>
      <path d="M50 13.11c12 0 13.44 0 18.18.26a24.84 24.84 0 018.36 1.55 13.88 13.88 0 015.17 3.37 13.88 13.88 0 013.37 5.17 24.84 24.84 0 011.55 8.36c.22 4.74.26 6.16.26 18.18s0 13.44-.26 18.18a24.84 24.84 0 01-1.55 8.36 15 15 0 01-8.54 8.54 24.84 24.84 0 01-8.36 1.55c-4.74.22-6.16.26-18.18.26s-13.44 0-18.18-.26a24.84 24.84 0 01-8.36-1.55 13.88 13.88 0 01-5.17-3.37 13.88 13.88 0 01-3.37-5.17 24.84 24.84 0 01-1.55-8.36c-.22-4.74-.26-6.16-.26-18.18s0-13.44.26-18.18a24.84 24.84 0 011.55-8.36 13.88 13.88 0 013.37-5.17 13.88 13.88 0 015.17-3.37 24.84 24.84 0 018.36-1.55c4.74-.22 6.16-.26 18.18-.26M50 5c-12.22 0-13.75 0-18.55.27a33.22 33.22 0 00-10.93 2.09 22.06 22.06 0 00-8 5.19 22.06 22.06 0 00-5.19 8 33.22 33.22 0 00-2.06 10.9C5.05 36.25 5 37.78 5 50s0 13.75.27 18.55a33.22 33.22 0 002.09 10.93 22.06 22.06 0 005.19 8 22.06 22.06 0 008 5.19 33.22 33.22 0 0010.93 2.09c4.77.24 6.3.24 18.52.24s13.75 0 18.55-.27a33.22 33.22 0 0010.93-2.09 23 23 0 0013.16-13.16 33.22 33.22 0 002.09-10.93C95 63.75 95 62.22 95 50s0-13.75-.27-18.55a33.22 33.22 0 00-2.09-10.93 22.06 22.06 0 00-5.19-8 22.06 22.06 0 00-8-5.19 33.22 33.22 0 00-10.9-2.06C63.75 5.05 62.22 5 50 5zm.09 22a23.13 23.13 0 1023.12 23.09A23.14 23.14 0 0050.09 27zm0 38.14a15 15 0 1115-15 15 15 0 01-15 15zm29.2-39.21a5.36 5.36 0 11-5.36-5.35 5.36 5.36 0 015.36 5.35zm0 0" />
    </svg>
  )
}

export default Instagram
