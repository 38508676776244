import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { useTranslation } from 'react-i18next'
import '../../utils/i18next'

import SEO from '../SEO/SEO'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import '../../assets/scss/global.scss'

export default ({ children, location, pageSlug }) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]')
  }

  // const [currentLang, setCurrentLang] = useState()
  // const isLangEn = currentLang === 'en'
  // const { i18n } = useTranslation()
  // function changeLanguage(lang) {
  //   i18n.changeLanguage(lang)
  //   setCurrentLang(lang)
  // }

  useEffect(() => {
    // Get correct height function with clearTimeout to prevent too many requests
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    const currentWidth = window.innerWidth

    let timeoutId = null
    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        if (currentWidth !== window.innerWidth) {
          // get correct height
          const vh = window.innerHeight * 0.01
          document.documentElement.style.setProperty('--vh', `${vh}px`)
        }
      }, 500)
    }
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  useEffect(() => {
    // Intersection Observer function
    const intersection = document.querySelectorAll('.intersection')
    const options = {
      root: null,
      rootMargin: '-50%',
      threshold: 0,
    }
    const observer = new IntersectionObserver(inView, options)
    intersection.forEach(intersection => {
      observer.observe(intersection)
    })

    function inView(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('inview') // Add inview class to current section
        } else {
          entry.target.classList.remove('inview') // Remove inview class to current section
        }
      })
    }
  })

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    // Fadein effect
    const section = document.querySelectorAll('.section')

    section.forEach(current => {
      gsap.from(current.querySelectorAll('.fadein'), {
        scrollTrigger: {
          trigger: current,
          start: 'top 60%',
          // toggleActions: 'play none none reverse',
        },
        opacity: 0,
        y: 30,
        duration: 0.6,
        ease: 'power1.inOut',
        stagger: 0.2,
      })
    })
  }, [])

  return (
    <>
      <SEO location={location} pageSlug={pageSlug} />
      <Header pageSlug={pageSlug} />
      <main>{children}</main>
      <Footer pageSlug={pageSlug} />
    </>
  )
}
