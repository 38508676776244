import React from 'react'

const Linkedin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      aria-labelledby="linkedin"
      role="presentation"
    >
      <title id="linkedin">Linkedin</title>
      <path d="M7.2 32.87h17.54V94H7.2V32.87zm8.33-7.65C1.53 25.7 1.61 3.48 15.65 4c13.88-.43 14 21.67-.12 21.21zM94.92 94H75V62.36c0-8.28-3.12-13.93-10-13.93-7.19.33-10.35 6.39-9.92 12.49V94H35.45s.26-56 0-61.13h19.71v9.59c1.16-4.2 7.46-10.18 17.5-10.18 12.47 0 22.26 8.8 22.26 27.72v34zm0 0" />
    </svg>
  )
}

export default Linkedin
