import React from 'react'

const Codepen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      aria-labelledby="codepen"
      role="presentation"
    >
      <title id="codepen">Codepen</title>
      <path d="M95 35.78v-.16l-.06-.33-.06-.18c0-.1-.07-.19-.1-.29l-.09-.19c0-.08-.08-.17-.13-.25l-.11-.18-.24-.2a1.83 1.83 0 00-.13-.16 1.29 1.29 0 00-.19-.21c0-.05-.1-.11-.15-.15l-.22-.19-.18-.13h-.06L52.15 5.65a3.87 3.87 0 00-4.3 0L6.72 33.07h-.06l-.18.13a1.9 1.9 0 00-.21.19 1.14 1.14 0 00-.16.15 1.29 1.29 0 00-.19.21l-.14.25-.16.24-.11.18c0 .08-.09.17-.13.25l-.09.19c0 .1-.07.19-.1.29l-.06.18-.06.33a.9.9 0 000 .16 2.92 2.92 0 000 .51v27.38a2.92 2.92 0 000 .51.9.9 0 000 .16l.06.33.06.18c0 .1.07.19.1.29l.09.19c0 .08.08.17.13.25l.11.18.16.23c0 .06.09.12.14.17l.19.21a1.14 1.14 0 00.16.15 1.9 1.9 0 00.21.19l.18.13.06.05 41.13 27.42a3.88 3.88 0 004.3 0l41.13-27.42.06-.05.18-.13.22-.19c.05 0 .1-.1.15-.15l.19-.21a1.09 1.09 0 00.13-.17 2 2 0 00.17-.23l.11-.18c.05-.08.09-.17.13-.25l.09-.19c0-.1.06-.19.1-.29l.06-.18.06-.33v-.16a4.36 4.36 0 000-.51V36.29a4.36 4.36 0 00.07-.51zM53.87 16.09l30.3 20.2-13.54 9.06-16.76-11.22zm-7.74 0v18L29.37 45.35l-13.54-9.06zm-33.4 27.44L22.41 50l-9.68 6.47zm33.4 40.37l-30.3-20.19 13.54-9.06 16.76 11.22zM50 59.15L36.32 50 50 40.85 63.68 50zm3.87 24.75v-18l16.76-11.25 13.54 9.06zm33.39-27.43L77.59 50l9.67-6.47z" />
    </svg>
  )
}

export default Codepen
