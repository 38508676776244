import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styles from './Header.module.scss'

const Header = ({ pageSlug, changeLanguage, currentLang, isLangEn }) => {
  const [isHero, setIsHero] = useState(false)
  const [isProjects, setIsProjects] = useState(false)
  const [isAbout, setIsAbout] = useState(false)

  useEffect(() => {
    if (pageSlug === 'home') {
      const heroClassList = document.querySelector('.hero').classList
      const projectsClassList = document.querySelector('.projects').classList
      const aboutClassList = document.querySelector('.about').classList

      window.addEventListener('scroll', () => {
        if (heroClassList.contains('inview')) {
          setIsHero(true)
        } else {
          setIsHero(false)
        }
        if (projectsClassList.contains('inview')) {
          setIsProjects(true)
        } else {
          setIsProjects(false)
        }
        if (aboutClassList.contains('inview')) {
          setIsAbout(true)
        } else {
          setIsAbout(false)
        }
      })
    }
  })

  // const langKey = () => {
  //   if (currentLang === 'en') {
  //     return null
  //   } else {
  //     return currentLang + '/'
  //   }
  // }

  // const url = `/${langKey}${pageSlug}`

  return (
    <header className={`header ${styles.header}`}>
      <div className={`header__container ${styles.header__container}`}>
        <div className={`lang ${styles.lang}`} style={{ display: 'none' }}>
          <div className="lang" style={{ display: 'none' }}>
            {/* <div className="lang__list"> */}
            {/* {isLangEn ? (
              <Link onClick={() => changeLanguage('ja')}>Ja</Link>
            ) : (
              <Link onClick={() => changeLanguage('en')}>En</Link>
            )} */}
          </div>
        </div>

        <nav className={`nav ${styles.nav}`}>
          <div
            className={`nav__list ${styles.nav__list} ${
              isHero ? 'current' : ''
            }`}
          >
            <Link to="/">HOME</Link>
          </div>
          <div
            className={`nav__list ${styles.nav__list} ${
              isProjects ? 'current' : ''
            }`}
          >
            <Link to="#projects">PROJECTS</Link>
          </div>
          <div
            className={`nav__list ${styles.nav__list} ${
              isAbout ? 'current' : ''
            }`}
          >
            <Link to="#about">ABOUT</Link>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
