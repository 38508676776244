import React from 'react'

const Twitter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      aria-labelledby="twitter"
      role="presentation"
    >
      <title id="twitter">Twitter</title>
      <path d="M84.4 23.62a19.29 19.29 0 008.11-10.77 35.81 35.81 0 01-11.73 4.73 18 18 0 00-13.47-6.15c-10.2 0-18.46 8.72-18.46 19.47a20.21 20.21 0 00.47 4.44A51.58 51.58 0 0111.27 15a20.23 20.23 0 00-2.5 9.79A19.8 19.8 0 0017 41a17.81 17.81 0 01-8.37-2.44v.24c0 9.44 6.36 17.31 14.82 19.09a17.47 17.47 0 01-8.34.34 18.63 18.63 0 0017.23 13.53 35.9 35.9 0 01-22.93 8.33A33.8 33.8 0 015 79.82a50.28 50.28 0 0028.3 8.75c34 0 52.53-29.67 52.53-55.41v-2.52A38.41 38.41 0 0095 20.56a35.08 35.08 0 01-10.6 3.06zm0 0" />
    </svg>
  )
}

export default Twitter
