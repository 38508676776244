import React, { useEffect, useRef } from 'react'

import Social from '../Social/Social'
import styles from './Footer.module.scss'

export default ({ pageSlug }) => {
  const ref = useRef()

  useEffect(() => {
    if (pageSlug === 'home') {
      const header = document.querySelector('.header')
      window.addEventListener('scroll', () => {
        if (ref.current !== null) {
          if (ref.current.classList.contains('inview')) {
            header.classList.add('scrolled')
          } else {
            header.classList.remove('scrolled')
          }
        }
      })
    }
  })
  return (
    <div className={`footer intersection section ${styles.footer}`} ref={ref}>
      <div className={`fadein ${styles.footer__content}`}>
        <div className={`${styles.contact}`}>info@ayaos.work</div>
        <div className={`${styles.social}`}>
          <Social />
        </div>
        <div className={styles.copyright}>
          © {new Date().getFullYear()} Ayana Osawa
        </div>
      </div>
    </div>
  )
}
