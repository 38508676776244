import React from 'react'

const Github = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      aria-labelledby="github"
      role="presentation"
    >
      <title id="github">Github</title>
      <path d="M5 51.14a46.06 46.06 0 0030.77 43.78c2.26.43 3.08-1 3.08-2.22s0-4-.06-7.85c-12.52 2.79-15.16-6.18-15.16-6.18-2.05-5.34-5-6.75-5-6.75-4.09-2.87.31-2.81.31-2.81 4.52.33 6.89 4.76 6.89 4.76 4 7 10.54 5 13.1 3.83a10 10 0 012.86-6.17c-10-1.16-20.5-5.12-20.5-22.8a18.11 18.11 0 014.63-12.39c-.46-1.16-2-5.85.44-12.2 0 0 3.78-1.25 12.38 4.72a41.71 41.71 0 0122.53 0c8.59-6 12.36-4.72 12.36-4.72 2.46 6.35.91 11 .45 12.2a18.1 18.1 0 014.62 12.39c0 17.72-10.52 21.62-20.54 22.77 1.62 1.42 3.05 4.24 3.05 8.54 0 6.17-.05 11.14-.05 12.66 0 1.23.81 2.67 3.09 2.22A46.08 46.08 0 0095 51.14C95 25.66 74.85 5 50 5S5 25.66 5 51.14zm0 0" />
    </svg>
  )
}

export default Github
