import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Twitter from '../../assets/svg/Twitter'
import Instagram from '../../assets/svg/Instagram'
import Linkedin from '../../assets/svg/Linkedin'
import Github from '../../assets/svg/Github'
import Codepen from '../../assets/svg/Codepen'
import styles from './Social.module.scss'

const Social = () => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      site {
        siteMetadata {
          author
          social {
            twitter
            instagram
            linkedin
            github
            codepen
          }
        }
      }
    }
  `)

  return (
    <div className={styles.social}>
      <a
        className="socialHover"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://twitter.com/${data.site.siteMetadata.social.twitter}`}
      >
        <Twitter />
      </a>
      <a
        className="socialHover"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.instagram.com/${data.site.siteMetadata.social.instagram}`}
      >
        <Instagram />
      </a>
      <a
        className="socialHover"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.linkedin.com/in/${data.site.siteMetadata.social.linkedin}`}
      >
        <Linkedin />
      </a>
      <a
        className="socialHover"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://github.com/${data.site.siteMetadata.social.github}`}
      >
        <Github />
      </a>
      <a
        className="socialHover"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://codepen.io/${data.site.siteMetadata.social.codepen}`}
      >
        <Codepen />
      </a>
    </div>
  )
}

export default Social
